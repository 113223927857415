<template>
  <div class="elderlyCareAppointmentDetail">
    <div class="section-warpper" v-if="!!detail">
      <div class="section-header">
        <v-swiper
          :autoplay="2000"
          :swiperData="detail.roomPic ? detail.roomPic.split(',') : []"
        >
        </v-swiper>
        <!-- <img src="./img/banner.png" alt="" /> -->
        <!-- <img :src="detail.picture" alt="" /> -->
      </div>

      <div class="section-title">
        {{ `${detail.roomName}-${detail.bedName}` }}
      </div>
      <div class="section-detail">
        <div class="detail-item" v-for="(key, index) in keys" :key="index">
          <div class="detail-item--name">
            {{ key | keyName }}
          </div>
          <div class="detail-item--value">
            {{ keyValue(key) }}{{ key | keyUnit }}
          </div>
        </div>

        <div class="detail-item">
          <div class="detail-item--name">备注</div>
          <div class="detail-item--value">护理费根据老人的评估等级而定</div>
        </div>
        <template v-if="!isAudio && detail.orderStatus == 1">
          <div class="detail-item">
            <div class="detail-item--name">开始日期</div>
            <div class="detail-item--value">{{ detail.startTime }}</div>
          </div>
          <div class="detail-item">
            <div class="detail-item--name">结束日期</div>
            <div class="detail-item--value">{{ detail.endTime }}</div>
          </div>
        </template>
      </div>

      <div
        class="section-form"
        v-if="isAudio && (detail.orderStatus == 0 || detail.orderStatus == 1)"
      >
        <div class="section-item">
          <div class="section-item--label">开始日期：</div>
          <div class="section-item--control" @click="clickTime('startTime')">
            <v-input
              v-model.trim="form.startTime"
              disabled
              inputAlign="right"
              placeholder="请填写开始日期"
            ></v-input>
          </div>
        </div>
        <div class="section-item">
          <div class="section-item--label">结束日期：</div>
          <div class="section-item--control" @click="clickTime('endTime')">
            <v-input
              v-model.trim="form.endTime"
              disabled
              inputAlign="right"
              placeholder="请填写结束日期"
            ></v-input>
          </div>
        </div>
      </div>

      <v-dateTimePicker
        :value="dateTime"
        :valueShow.sync="dateTimeShow"
        :isAuto="true"
        type="date"
        title="选择完整时间"
        @confirmPicker="confirmDate"
      ></v-dateTimePicker>

      <div
        class="surebtn"
        v-if="!isAudio && (detail.orderStatus == 0 || detail.orderStatus == 1)"
      >
        <div class="btn" @click="cancel">取消预约</div>
      </div>

      <div
        class="surebtn"
        v-if="isAudio && (detail.orderStatus == 0 || detail.orderStatus == 1)"
      >
        <div class="btn success" @click="submit(1)">
          {{ detail.orderStatus == 1 ? "更新日期" : "审核通过" }}
        </div>
        <div class="btn" v-if="detail.orderStatus == 0" @click="submit(2)">
          驳回
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { orderDetailByIdURL, cancelOrderURL, orderAuditURL } from "./api";
import { statusMap, orderStatusMap, btnStatusMap } from "./map";

export default {
  data() {
    return {
      id: "",
      isAudio: false,
      detail: {
        roomName: "",
        bedName: "",
        rentalStatus: "",
        monthlyRent: "",
        roomOrientation: "",
        floorNumber: "",
        contactsName: "",
        contactsMobile: "",
        roomType: "",
        roomArea: "",
        orderStatus: 2,
      },
      // detail: null,
      isClick: true,
      keys: [
        "rentalStatus",
        "monthlyRent",
        "roomOrientation",
        "floorNumber",
        "contactsName",
        "contactsMobile",
        "roomType",
        "roomArea",
      ],
      form: {
        startTime: "",
        endTime: "",
      },
      timer: null,
      /* v-dateTimePicker */
      timeStr: "",
      dateTime: new Date(),
      dateTimeShow: false,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  filters: {
    btnTxt(status) {
      return btnStatusMap[status];
    },
    btnCss(status) {
      // let btnStatusCSS = {
      //   0: {
      //     color: "#FFFFFF",
      //     background: "#7CC0A3",
      //   },
      //   // 1: {
      //   //   color: "#DA9797",
      //   //   background: "#FCF2F3",
      //   // },
      //   // 2: {
      //   //   color: "#FFFFFF",
      //   //   background: "#D93747",
      //   // },
      // };
      // return btnStatusCSS[status];
      return { color: "#ffffff", background: "#7cc0a3" };
    },
    keyName(key) {
      const names = {
        rentalStatus: "出租状态",
        monthlyRent: "月单价",
        roomOrientation: "朝向",
        floorNumber: "楼层",
        contactsName: "联系人",
        contactsMobile: "联系电话",
        roomType: "房间类型",
        roomArea: "房间面积",
      };
      return names[key];
    },
    keyUnit(key) {
      const keys = {
        rentalStatus: "",
        monthlyRent: "/月",
        roomOrientation: "",
        floorNumber: "层",
        contactsName: "",
        contactsMobile: "",
        roomType: "",
        roomArea: "m²",
      };
      return keys[key];
    },
  },
  created() {
    this.initData();
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    initData() {
      const { id, isAudio } = this.$route.query;
      this.id = id;
      this.isAudio = !!isAudio;
      this.getDetail();
    },
    // 取消订单
    async cancel() {
      if (!this.isClick) {
        return;
      }
      this.isClick = false;
      let params = {
        id: this.id,
      };
      let res = await this.$axios.post(cancelOrderURL, null, { params });
      if (res.code === 200 && res.data) {
        this.$toast({
          message: "操作成功，3s后自动跳转列表页面",
          forbidClick: true,
          duration: 3000,
        });
        const _this = this;
        this.timer = setTimeout(() => {
          _this.$toast.clear();
          // _this.$router.replace({
          //   name: "myDiscussionList",
          // });
          _this.$router.go(-1);
        }, 2500);
        return;
      }
      this.isClick = true;
    },
    async submit(auditStatus) {
      if (!this.valid(auditStatus)) {
        return;
      }
      if (!this.isClick) {
        return;
      }
      this.isClick = false;
      let params = {
        id: this.id,
        auditStatus, // 1-审核通过 2-审核驳回
        ...this.form,
      };
      let res = await this.$axios.post(orderAuditURL, params);
      if (res.code === 200) {
        this.$toast({
          message: "操作成功，3s后自动跳转列表页面",
          forbidClick: true,
          duration: 3000,
        });
        const _this = this;
        this.timer = setTimeout(() => {
          _this.$toast.clear();
          _this.$router.go(-1);
        }, 2500);
      } else {
        this.isClick = true;
      }
    },
    // 验证必填项
    valid(val) {
      const { startTime, endTime } = this.form;
      let message = undefined;
      if (val == 1 && startTime === "") {
        message = "开始日期不可为空";
      } else if (val == 1 && endTime === "") {
        message = "结束日期不可为空";
      } else if (
        moment(endTime.replace(/-/g, "/")).isBefore(
          startTime.replace(/-/g, "/")
        )
      ) {
        message = "开始日期不能大于结束日期";
      }
      if (message) {
        this.$toast({
          message,
          duration: 1500,
        });
      }
      return !message;
    },
    async getDetail() {
      let params = {
        id: this.id,
      };
      let res = await this.$axios.get(orderDetailByIdURL, { params: params });
      if (res.code === 200 && res.data) {
        this.detail = res.data;
        if (this.detail.startTime) {
          this.form.startTime = this.detail.startTime;
          this.form.endTime = this.detail.endTime;
        }
      }
    },
    clickTime(str) {
      this.timeStr = str;
      this.dateTimeShow = true;
    },

    // 选择日期
    confirmDate(val) {
      this.form[this.timeStr] = moment(val).format("YYYY-MM-DD");
    },

    // 获取值
    keyValue(key) {
      if (key === "rentalStatus") {
        return statusMap[this.detail[key]];
      }
      return this.detail[key];
    },
  },
};
</script>

<style lang="less" scoped>
.elderlyCareAppointmentDetail {
  box-sizing: border-box;
  width: 100%;
  // background: #f6f8fb;
  font-family: PingFangSC-Regular, PingFang SC;
  padding: 20px 30px;
  padding-bottom: calc(
    40px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    40px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-warpper {
    min-height: calc(100vh - 60px - constant(safe-area-inset-bottom));
    min-height: calc(100vh - 60px - env(safe-area-inset-bottom));
    // background: #ffffff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    // padding: 50px 36px 44px;
    // border-radius: 16px;
  }
  .section-header {
    margin-bottom: 40px;
    border-radius: 16px;
    width: 100%;
    height: 330px;
    overflow: hidden;
    // img {
    //   overflow: hidden;
    // }
  }
  .section-title {
    font-size: 52px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 72px;
    margin-bottom: 40px;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
    padding: 0 18px;
  }
  .section-detail {
    font-size: 48px;
    font-weight: 400;
    color: rgba(0, 0, 0);
    line-height: 84px;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
    text-align: left;
    padding: 0 18px;
    // font-weight: bold;
    .detail-item {
      &:not(:first-child) {
        margin-top: 10px;
      }
      display: flex;
      // align-items: center;
      .detail-item--name {
        width: 260px;
        height: 100%;
        min-width: 260px;
        margin-right: 44px;
      }
      .detail-item--value {
        flex: 1;
        line-height: 72px;
        color: rgba(0, 0, 0, 0.5);
        padding: 12px 0;
      }
    }
  }
  .section-form {
    margin-top: 20px;
    border-top: 4px solid #f6f6f6;
    border-bottom: 4px solid #f6f6f6;
    .section-item {
      display: flex;
      // border-bottom: 4px solid rgba(0, 0, 0, 0.04);
      .section-item--label {
        flex: 1;
        line-height: 74px;
        color: #333333;
        font-size: 52px;
        text-align: left;
        font-weight: 400;
        white-space: nowrap;
        padding: 36px 0 30px;
      }
      .section-item--control {
        max-width: 400px;
        display: flex;
        align-items: center;
        ::v-deep .van-cell {
          font-size: 42px;
          .van-field__control {
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }

  .surebtn {
    margin-top: 78px;
    width: 100%;
    // display: flex;
    // justify-content: center;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 94px;
      // background: linear-gradient(270deg, #c4e2ff 0%, #9ccdff 100%);
      border-radius: 16px;
      // border: 8px solid #ecf7ff;
      font-weight: bold;
      font-size: 46px;
      color: #ffffff;
      letter-spacing: 4px;
      background: #96444c;
      &:not(:first-child) {
        margin-top: 32px;
      }
      &.success {
        background: #138347;
      }
    }
  }
}
</style>
